// Colors
$logo-blue: #002942;
$logo-green: #68a62b;
$error-red: red;
$success-green: green;
$warning-orange: orange;
$skill-unmatch: #fdd;
$skill-match: #cfc;
$responsibility: #428bca;
$notification-orange: #f56f0f;

// Declarations used by "@import bootstrap" to generate themes
$theme-colors: (
  "responsibility": $responsibility,
  "skill": $skill-unmatch,
  "skill-match": $skill-match,
  "notification": $notification-orange,
  "logo-blue": $logo-blue
);

@import "../../node_modules/bootstrap/scss/bootstrap";

// Custom declarations
$menu-logo-height: 3rem;
$header-padding: $menu-logo-height + 2 * ($navbar-padding-y + $navbar-brand-padding-y);

body {
  // To avoid overlap with the fixed header navbar
  padding-top: $header-padding;
}

// Errors on forms
.form-error {
  text-align: center;
  color: $error-red;
}

// Center buttons at the end of forms and move extraneous spacing after last form element as styled by Bootstrap
.last-button-group {
  text-align: center;
  margin-bottom: 0;
}

.input-form {
  .action-link {
    padding: 0;
  }
}

// Reduce space for icons in their own columns (columns must be xs="auto" or md="auto" etc.)
.icon-column.col-auto {
  padding-left: 0;

  .btn {
    padding: 0;
    margin: 0;
  }
}

// Make Material UI icons vertically centered with surrounding text
.inline-icon {
  vertical-align: top;
}

// When display a row of buttons, center them and space them
.button-row {
  text-align: center;

  > * + * {
    // Space between buttons in a row
    margin-left: 1rem;
  }
}

// Add a standard vertical space between a container's first-level children
.spaced-children {
  > * + * {
    margin-top: 1rem;
  }
}

// Add a vertical space between columns of a row but only when they wrap
.wrap-spaced-children {
  $vspace: 1rem;

  margin-top: -$vspace;
  > * {
    margin-top: $vspace;
  }
}

